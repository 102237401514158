
import React, { useEffect } from 'react'

import './contact.css'
import { MdOutlineMail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { BsWhatsapp } from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'
import { motion, useInView } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { changeNavbar } from '../../store/Slices/NavSlice'

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);



const contactDetail = [{
  icon: "MdOutlineMail",
  h4: "Email",
  h5: "contact@santoshgiri.com.np",
  href: "mailto:contact@santoshgiri.com.np",
  a: "Send a message"
},
{
  icon: "RiMessengerLine",
  h4: "Messenger",
  h5: "Santosh Giri",
  href: "https://www.facebook.com/profile.php?id=100007645997906",
  a: "Send a message"
}, {
  icon: "BsWhatsapp",
  h4: "WhatsApp",
  h5: "+9779815054626",
  href: "https://api.whatsapp.com/send?phone+9779815054626",
  a: "Send a message"
},
]

const AnimatedContact = () => {

  const contactMotionProps = {
    
    ...(isMobile ? {} : {
      initial: {
        x: -500,
      },
      animate: {
        x: 50,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.5
        }
      },
    })
  }

  return (
    <>
      {contactDetail.map((item, idx) => {
        return <motion.article {...contactMotionProps}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1.5 }}
          className="contact__option">
          {idx === 0 && <MdOutlineMail className='contact__option-icons' />}
          {idx === 1 && <RiMessengerLine className='contact__option-icons' />}
          {idx === 2 && <BsWhatsapp className='contact__option-icons' />}


          <h4>{item.h4}</h4>
          <h5 className='text-sm'>{item.h5}</h5>
          <a href={item.href} target='_blank'>{item.a}</a>
        </motion.article>

      })}

    </>
  )
}





const Contact = () => {

  const contactRef = useRef(null);
  const isInView = useInView(contactRef);
  const dispatch = useDispatch();

  const changeNavbarState = (isInView) => {
    if (isInView) {
      dispatch(changeNavbar("contact"))
    }

  }

  useEffect(() => {
    changeNavbarState(isInView)
  }, [isInView])



  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_emelivp', 'template_7x5hp5i', form.current, 'OY1LWgSGSGal4_tkY')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  const formMotionProps = {
    
    ...(isMobile ? {} : {
      initial: {
        x: 1500,
      },
      animate: {
        x: 50,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.5
        }
      },
    })
  }


  return (
    <section ref={contactRef} id='contact'>
      <h5 >Get In Touch</h5>
      <h2 >Contact</h2>
      <div className="container contact__container">
        <div className="contact__options" >

          {isInView ?  <AnimatedContact />: <></>}

        </div>
        {/* end of contact options */}
        {isInView ? <motion.form {...formMotionProps}
         ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' className='p-3' required />
          <input type="email" name='email' placeholder='Your Email' className='p-3' required />
          <textarea  name="message" rows="7" placeholder='Your Message' className='p-3' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>

        </motion.form> : <></>}

      </div>
    </section>
  )
}

export default Contact