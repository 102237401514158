import React, { useEffect, useRef } from 'react'
import  DancingLines from 'react-dancing-lines'
import { Route, Routes, Navigate, Router } from 'react-router-dom'
import Header from './components/Header/Header'
import Nav from './components/nav/Nav'
import About from './components/About/About'
import Experience from './components/Experience/Experience'
import Service from './components/services/Service'
import Portfolio from './components/portfolio/Portfolio'
import Testimonial from './components/Testimonials/Testimonial'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import Pagenotfound from './components/PageNotFound/Pagenotfound'

import All from './components/All/All'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from "./store/Reducer";


const App = () => {

 

  return (
    <>
   

    <Provider store={store}>
    <DancingLines />
      <Header />
      <Nav />
      <About />
      <Experience />
      <Service />
      <Portfolio />
      <Testimonial />
      <Contact />
      <Footer />
      

    </Provider>

    </>
    
  )
}

export default App