import './About.css'
import React, { useEffect, useRef } from 'react'
import ME from '../../assets/about--me.jpeg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'
import CV from '../../assets/santosh-giri-cv.pdf'
import { motion } from "framer-motion"
import { useInView } from "framer-motion"
import { useDispatch } from 'react-redux'
import { changeNavbar } from '../../store/Slices/NavSlice'

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const aboutMe = [{
  h5: "Experience",
  small: "3+ Years Working"
},
{
  h5: "Clients",
  small: "10+ worldwide"
}, {
  h5: "Projects",
  small: "15+ completed"
},
]

const AnimatedBox = () => {
  return (

    <>

      {aboutMe.map((item, idx) => {
        return <motion.article
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.5 }}
          key={idx}
          
          initial={{ scale: 0 }}
          animate={{ rotate: 360, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
          className="about__card">
          {idx === 0 && <FaAward className='about__icon' />}
          {idx === 1 && <FiUsers className='about__icon' />}
          {idx === 2 && <VscFolderLibrary className='about__icon' />}
          <h5>{item.h5}</h5>
          <small>{item.small}</small>
        </motion.article>

      })}
    </>)

}




const About = () => {


  const aboutRef = useRef(null);
  const isInView = useInView(aboutRef)
  const dispatch = useDispatch();

  const changeNavbarState = (isInView) => {
    if (isInView) {
      dispatch(changeNavbar("about"))
    }
  }

  useEffect(() => {
    changeNavbarState(isInView)
  }, [isInView])

  const ImgmotionProps = {
    
    ...(isMobile ? {} : {
      initial: {
        x: -500,
      },
      animate: {
        x: 50,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.5
        }
      },
    })
  };
  const PmotionProps = {
    
    ...(isMobile ? {} : {
      initial: {
        x: 1500,
      },
      animate: {
        x: 1,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.5
        }
      },
    })
  };
  


  return (
    <section ref={aboutRef} id='about' className='section_height'>
      <h5>Get To Know</h5>
      <h2 >About Me</h2>

      <div className="container about__container">
        {isInView ? <motion.div className="about__me" {...ImgmotionProps}>
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </motion.div> : <div

          className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>}

        <div className="about__content">
          <div className="about__cards" >

            {isInView && <AnimatedBox />}


          </div>
          {isInView ?
            <motion.p {...PmotionProps}>
              It's me santosh, from Jhapa, Nepal. Currently I'm a student
              of Computer Science (CS). I'm a self taught programmer. I'm
              learning programming and IT stuff for more than 3 years.
              I've a good experience with more than 10+ clients. I love
              to do my work so I take all my clients Projects as my own.
              Thank you!
            </motion.p> : <></>}
          <div className='condown'>
            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
            <a href={CV} download className='btn'>Download CV</a>
          </div>

        </div>
      </div>

    </section>
  )
}

export default About