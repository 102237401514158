import { useInView } from 'framer-motion';
import './Experience.css'
import React, { useEffect, useRef } from 'react'
import { BsPatchCheckFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux';
import { changeNavbar } from '../../store/Slices/NavSlice';
import { motion } from "framer-motion";


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


const Experience = () => {
  const experienceRef = useRef(null);
  const isInView = useInView(experienceRef)
  const dispatch = useDispatch();

  const changeNavbarState = (isInView) => {
    if (isInView) {
      dispatch(changeNavbar("experience"))
    }

  }

  useEffect(() => {
    changeNavbarState(isInView)
  }, [isInView])


  const frontExMotionProps = {
    
    ...(isMobile ? {} : {
      initial: {
        x: -500,
      },
      animate: {
        x: 50,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.5
        }
      },
    })
  };
  const backExMotionProps = {
    
    ...(isMobile ? {} : {
      initial: {
        x: 500,
      },
      animate: {
        x: 50,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.5
        }
      },
    })
  };



  return (
    <section ref={experienceRef} id='experience'>
      <h5>What Skills I have</h5>
      <h2 >My Experience</h2>
      <div className="container experience__container">
        {isInView ? <motion.div {...frontExMotionProps}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.5 }}
          className="experince__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className='experience__details' >
              <BsPatchCheckFill />
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>NextJS</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Tailwind</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>React</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div>
        </motion.div> : <></>}
        {/* end of front end */}
        {isInView ? <motion.div {...backExMotionProps}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.5 }}
          className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>MongoDB</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Node JS</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>MySQL</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Prisma</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Express JS</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

          </div>
        </motion.div> : <></>}
      </div>
    </section>
  )
}

export default Experience