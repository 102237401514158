import { configureStore } from '@reduxjs/toolkit'
import NavSlice from './Slices/NavSlice';


const store = configureStore({
    reducer: {
        navbar: NavSlice,
    }
})

export default store;
