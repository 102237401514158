import React from 'react'
import Header from '../Header/Header'
import Nav from '../nav/Nav'
import About from '../About/About'
import Experience from '../Experience/Experience'
import Service from '../services/Service'
import Portfolio from '../portfolio/Portfolio'
import Testimonial from '../Testimonials/Testimonial'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'
import Pagenotfound from '../PageNotFound/Pagenotfound'

function All() {
  return (
    <>
    <Header />
    <Nav />
    <About />
    <Experience />
    <Service />
    <Portfolio />
    <Testimonial />
    <Contact />
    <Footer />
    </>
  )
}

export default All