import React, { useEffect, useRef } from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/logo-me.png";
import HeaderSocial from "./HeaderSocial";
import { useDispatch } from "react-redux";
import { useInView } from "framer-motion";
import { changeNavbar } from "../../store/Slices/NavSlice";

const Header = () => {
  const dispatch = useDispatch();

  const homeRef = useRef(null);
  const isHomeView = useInView(homeRef);

  const changeNavbarState = (isHomeView) => {
    if (isHomeView) {
      dispatch(changeNavbar("home"));
    }
  };

  useEffect(() => {
    changeNavbarState(isHomeView);
  }, [isHomeView]);

  return (
    <header ref={homeRef}>
      <div className="container header__container">
        <div className="shadow">
          {/* <h5>Hello,</h5> */}
          <h1>Welcome</h1>
          <h5>It's me</h5>
          <h2>Santosh Giri</h2>
          <h5 className="text-light">Web Developer</h5>
        </div>
        {/* <CTA /> */}
        <HeaderSocial />
        
        <a href="#contact" className="scroll_down">
          scroll_down
        </a>
      </div>
    </header>
  );
};

export default Header;
