import React, { useEffect, useRef } from "react";
import "./portfolio.css";
import IMG1 from "../../assets/newportfolio1.png";
import IMG2 from "../../assets/portfolio2.png";
import IMG3 from "../../assets/portfolio3.png";
import IMG4 from "../../assets/portfolio4.png";

import IMG5 from "../../assets/portfolio5.png";
import IMG6 from "../../assets/portfolio6.png";
import IMG7 from "../../assets/portfolio7.png";



// import IMG5 from '../../assets/portfolio5.png'
// import IMG6 from '../../assets/portfolio6.jpg'
import { useInView } from "framer-motion";
import { useDispatch } from "react-redux";
import { changeNavbar } from "../../store/Slices/NavSlice";
import { motion } from "framer-motion";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css/grid"


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const data = [
  {
    id: 1,
    image: IMG6,
    title: "Self-Driving-Car With Vanilla JS",
    github: "https://github.com/santosh2345/self-driving-car-nolibrary",
    demo: "https://self-driving-car-nolib.netlify.app/",
    tools: "HTML CSS JAVASCRIPT",
    desc: "",
  },
  {
    id: 2,
    image: IMG3,
    title: "Code-Together, Real-time code editor",
    github: "https://github.com/santosh2345/CodeTogether",
    demo: "https://codetogether-hd50.onrender.com/",
    tools: "React  Node Express WebSockets CodeMirror ",
    desc: "",
  },
  {
    id:3,
    image:IMG7,
    title:"Sanopen - A working Codepen Clone",
    github: "https://github.com/santosh2345/sanopen",
    demo: "https://sanopen.netlify.app/",
    tools:"React MUI Codemirror2 ",
    desc:"",

  },
  {
    id: 4,
    image: IMG2,
    title: "Front-End of Bookstore Website",
    github: "https://github.com/santosh2345/onlineBookStore",
    demo: "https://santoshbookstore.netlify.app/",
    tools: "HTML CSS JAVASCRIPT",
    desc: "",
  },
  {
    id: 5,
    image: IMG4,
    title: "MERN Stack Project",
    github: "https://github.com/santosh2345/mern-project-final",
    demo: "https://santosh-mern.onrender.com/",
    tools: "REACT NODEJS EXPRESSJS MONGODB JWT SOCKET.IO",
    desc: "",
  },
  {
    id: 6,
    image: IMG5,
    title: "Weather920, Weather info using API",
    github: "https://github.com/santosh2345/weatherApp",
    demo: "https://weather920.surge.sh/",
    tools: "HTML CSS JAVASCRIPT OPENWEATHER(API)",
    desc: "",
  },
  {
    id: 7,
    image: IMG1,
    title: "To-do App",
    github: "https://github.com/santosh2345/todo-app-tailwind-final",
    demo: "https://santosh-worklist.netlify.app/",
    tools: "HTML CSS JAVASCRIPT TAILWIND",
    desc: "",

  },
  
];

const Portfolio = () => {
  const portfolioRef = useRef(null);
  const isInView = useInView(portfolioRef);
  const dispatch = useDispatch();

  const changeNavbarState = (isInView) => {
    if (isInView) {
      dispatch(changeNavbar("portfolio"));
    }
  };

  useEffect(() => {
    changeNavbarState(isInView);
  }, [isInView]);

  const portMotionProps = {
    ...(isMobile
      ? {}
      : {
          initial: {
            x: -500,
          },
          animate: {
            x: 1,
            transition: {
              duration: 2,
              type: "spring",
              bounce: 0.5,
            },
          },
        }),
  };
  let slideNumber = 3;

  if(isMobile){
    slideNumber = 1;
  }
  else{
    slideNumber= 3;
  }

  return (
    <section ref={portfolioRef} id="portfolio" >
      <h5> My Recent Work</h5>
      <h2>Portfolio</h2>
      
        <Swiper 
        
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        slidesPerView={slideNumber}
        navigation
        A11y
        pagination={{ clickable: true }}
        >




      {/* <div className="container portfolio__container "
      id="FirstSwiperrr"> */}
        {data.map(({ id, image, title, github, demo,tools, desc }) => {
          
          return (
            <>
            <SwiperSlide id="FirstSwiperrr">

         
            
            
              {isInView ? (
               
                <motion.article
                  {...portMotionProps}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 1.5 }}
                  key={id}
                  className="portfolio__item"
                >
                  <div class="flip-box">
                    <div class="flip-box-inner">
                      <div className="portfolio__item-image projectImage  flip-box-front">
                        <img src={image} alt={title} />
                      </div>
                      <div class="flip-box-back">
                        <h2>Used Technologies</h2>
                        <p>{tools}</p>
                      </div>
                    </div>
                  </div>

                  <div className="portfolio__item-image">
                    <h3>{title}</h3>
                    <div className="portfolio__item-cta">
                      <a href={github} className="btn" target="_blank">
                        Github
                      </a>
                      <a
                        href={demo}
                        className="btn btn-primary"
                        target="_blank"
                      >
                        Visit
                      </a>
                    </div>
                  </div>
                </motion.article>
              ) : (
                <></>
              )}


              </SwiperSlide>
            
          </>
          )
        })}
      {/* </div> */}

        </Swiper>
        
    </section>
  );
};

export default Portfolio;
