
import React from 'react'
import './Footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import Framer from '../Framer/Framer'
const Footer = () => {
  return (
   <footer>
    <a href="#"className='footer__logo'>Thanks For Visiting!</a>
    <ul className='permalinks'>
      <li><a href="#">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#experience">Experience</a></li>
      <li><a href="#services">Services</a></li>
      <li><a href="#portfolio">Portfolio</a></li>
      <li><a href="#testimonial">Testimonials</a></li>
      <li><a href="#contact">Contact</a></li>


    </ul>
    <div className="footer__socials">
      <a href="https://www.facebook.com/profile.php?id=100007645997906" target='_blank'><FaFacebookF /></a>
      <a href="https://www.instagram.com/santosh_girii/" target='_blank'><FiInstagram /></a>
      <a href="https://twitter.com/santoshhgiri" target='_blank'><IoLogoTwitter /></a>
    </div>
    <div className="footer__copyright">
    <small className='photography'><a href="https://sanphotography.surge.sh/" target='_blank'>#CheckoutMyPhotography</a></small>
    <small className='photography'><a href="#" target='_blank'>#Articles</a></small>


      <small>Santoshgiri &copy; 2023 </small>
    </div>
   
   </footer>
  )
}

export default Footer