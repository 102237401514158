import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaTwitter} from 'react-icons/fa'

const HeaderSocial = () => {
  return (
    <div className='header_social'>
        <a href="https://www.linkedin.com/in/santosh-giri-721366215/ " target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/santosh2345" target="_blank"><FaGithub /> </a>

        <a href="https://twitter.com/santoshhgiri " target="_blank"><FaTwitter /> </a>


    </div>
  )
}

export default HeaderSocial