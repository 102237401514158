import "./nav.css";
import React, { useEffect, useRef } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { HiOutlineFolder } from "react-icons/hi"
import { FiPhone } from "react-icons/fi";
import { useState } from "react";
import { useSelector } from 'react-redux'


const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  const currentNav = useSelector((state) => state.navbar);
  


  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={currentNav ==="home" ? 'active' : ''} >
        <AiOutlineHome />
      </a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={currentNav === 'about' ? 'active' : ''}>
        <AiOutlineUser />
      </a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={currentNav === 'experience' ? 'active' : ''}>
        <BiBook />
      </a>
      <a href="#services" onClick={() => setActiveNav('#services')} className={currentNav === 'service' ? 'active' : ''}>
        <RiServiceLine />
      </a>
      <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={currentNav === 'portfolio' ? 'active' : ''}>
        <HiOutlineFolder />
      </a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={currentNav === 'contact' ? 'active' : ''}>
        <FiPhone />
      </a>

    </nav>
  );
};

export default Nav;
