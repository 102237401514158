

import React, { useEffect, useRef } from 'react'
import './service.css'
import { BiCheck } from 'react-icons/bi'
import { useDispatch } from 'react-redux';
import { useInView } from 'framer-motion';
import { changeNavbar } from '../../store/Slices/NavSlice';
import { motion } from 'framer-motion'


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


const Service = () => {
  const serviceRef = useRef(null);
  const isInView = useInView(serviceRef)
  const dispatch = useDispatch();

  const changeNavbarState = (isInView) => {
    if (isInView) {
      dispatch(changeNavbar("service"))
    }
  }

  useEffect(() => {
    changeNavbarState(isInView)
  }, [isInView])



  const leftServiceMotionProps = {
    
    ...(isMobile ? {} : {
      initial: {
        x: -500,
      },
      animate: {
        x: 1,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.5
        }
      },
    })
  }


  const rightServiceMotionProps = {
    
    ...(isMobile ? {} : {
      initial: {
        x: 500,
      },
      animate: {
        x: 1,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.5
        }
      },
    })
  }

  return (
    <section ref={serviceRef} id='services'>
      <h5>What I Offer</h5>
      <h2 >Services</h2>
      <div className="container services__container">
        {isInView ? <motion.article {...leftServiceMotionProps}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.5 }}
          className='service' >
          <div className="service__head">
            <h3>Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>Custom website design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>User interface design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Responsive design for mobile devices</p>
            </li>


          </ul>
        </motion.article> : <></>}
        {/* end of UI/UX */}
        <motion.article 

        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.5 }}
        className='service'>
          <div className="service__head">
            <h3>Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>Front-end development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Back-end development</p>
            </li>
            <li >
              <BiCheck className='service__list-icon' />
              <p>E-commerce development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Portfolio website development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Custom web application development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>SEO (Search Engine Optimization)</p>
            </li>

          </ul>
        </motion.article>
        {/* end of webdevelopment */}
        {isInView ? <motion.article {...rightServiceMotionProps}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.5 }}
          className='service'>
          <div className="service__head">
            <h3>Deploy</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>Web hosting setup and configuration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Domain name registration and management</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Site migration from one host to another</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performance optimization and speed testing</p>
            </li>
            {/* <li>
              <BiCheck className='service__list-icon' />
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
            </li> */}

          </ul>
        </motion.article> : <></> }
        {/* end of content creation*/}

      </div>
    </section>
  )
}

export default Service