import { createSlice, configureStore } from '@reduxjs/toolkit'

const NavSlice = createSlice({
  name: "NavState",
  initialState: [], 
  reducers: {
    changeNavbar(state, action) {
      return action.payload
     },
  }
})


export default NavSlice.reducer;
export const {changeNavbar} = NavSlice.actions;